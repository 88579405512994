import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Helmet } from 'react-helmet'
import PageTitle from '../components/Blocks/PageTitle'
import { WORDPRESS_URL } from '../config'

const LocationTemplate = ({
  title,
  content,
  locationInformation,
  featuredImage,
  locationFeatures,
}) => {
  return (
    <div className="w-full">
      <PageTitle title="D&M Leasing">
        <p className="text-center font-bold text-lg">{title}</p>
      </PageTitle>
      <div className="w-full grid grid-cols-1 md:grid-cols-2">
        <div
          className="bg-brand-blue p-10 md:p-20 flex flex-col justify-center items-start"
          style={{ minHeight: '300px' }}
        >
          <h2 className="font-bold text-white mb-4">GET IN TOUCH</h2>
          <p className="text-white text-lg">D&M Leasing</p>
          <p className="text-white text-lg max-w-lg">
            {locationInformation.locationAddress}
          </p>
          <a
            href={`tel:${locationInformation.locationPhone}`}
            className="text-white text-lg max-w-xs font-thin "
          >
            {locationInformation.locationPhone}
          </a>
          <a
            href={`tel:${locationInformation.locationEmail}`}
            className="text-white text-lg max-w-xs font-thin underline"
          >
            {locationInformation.locationEmail}
          </a>
          <div
            className="text-white font-thin mt-4"
            dangerouslySetInnerHTML={{
              __html: locationInformation.locationHours,
            }}
          />

          <a
            target="_blank"
            rel="noreferrer"
            href={locationInformation.locationDirectionsLink}
            className="text-lg uppercase py-4 px-10  border-2 border-white rounded-full mt-10 text-white hover:text-brand-green hover:bg-white hover:border-brand-green transition-all"
          >
            get directions
          </a>
        </div>
        <div
          className="hidden md:block w-full h-full bg-cover bg-center relative"
          style={{
            backgroundImage: `url(${WORDPRESS_URL}/wp-content/uploads/2018/04/iStock-140276231.jpg)`,
            minHeight: '300px',
          }}
        >
          <div className="w-full h-5 md:h-full md:w-5 absolute bottom-5 left-auto md:left-5 md:bottom-auto 5 bg-brand-blue opacity-40" />
          <div className="w-full h-5 md:h-full md:w-5 absolute bottom-0 left-auto md:left-0 bg-brand-blue opacity-80" />
        </div>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2">
        <div
          className="hidden md:block w-full h-full bg-cover bg-center relative"
          style={{
            backgroundImage: `url(${featuredImage?.node.mediaItemUrl})`,
            minHeight: '300px',
          }}
        />

        <div
          className="bg-brand-blue p-10 md:p-20 flex flex-col justify-center items-center"
          style={{ minHeight: '300px' }}
        >
          <div
            className="transition-all text-white "
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>

      <div className="flex flex-wrap justify-center md:justify-between w-full items-center mx-auto p-8 md:p-20 max-w-screen-xl my-10">
        {locationFeatures?.locationBullets.map(bullet => (
          <div
            key={bullet.locationBulletText}
            className="text-center my-10 md:my-0"
          >
            <img
              src={bullet.loocationBulletImage.mediaItemUrl}
              alt={bullet.loocationBulletImage.altText}
              className="mx-auto"
            />
            <p className="max-w-xs mx-auto text-lg font-thin">
              {bullet.locationBulletText}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

const Page = ({ data, pageContext }) => {
  const { wpLocation: location } = data
  return (
    <Layout pageContext={pageContext}>
      <Seo seo={location.seo} />
      <Helmet>
        {location.slug === 'dallas-location' && (
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "AutoDealer",
              "name": "D&M Leasing Dallas",
              "image": "https://www.dmautoleasing.com/dm-white.svg",
              "@id": "https://schema.org/AutoDealer",
              "url": "https://www.dmautoleasing.com/contact-us/dallas-location/",
              "telephone": "(972) 355-3273",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "17090 Dallas Pkwy, #200",
                "addressLocality": "Dallas",
                "addressRegion": "TX",
                "postalCode": "75248",
                "addressCountry": "US"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 32.9821797,
                "longitude": -96.827856199999985
              },
              "openingHoursSpecification": [{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
                ],
                "opens": "08:30",
                "closes": "18:00"
              },{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Saturday",
                "opens": "09:00",
                "closes": "14:00"
              }],
              "sameAs": [
                "https://www.facebook.com/dmautoleasing/",
                "https://www.instagram.com/dmleasing/",
                "https://www.pinterest.com/dmleasing/"
              ]
            }`}
          </script>
        )}
        {location.slug === 'ft-worth-location' && (
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "AutoDealer",
              "name": "D&M Leasing Fort Worth",
              "image": "https://www.dmautoleasing.com/dm-white.svg",
              "@id": "https://schema.org/AutoDealer",
              "url": "https://www.dmautoleasing.com/contact-us/ft-worth-location/",
              "telephone": "(817) 355-3273",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "1400 W 7th St, Ste 200",
                "addressLocality": "Fort Worth",
                "addressRegion": "TX",
                "postalCode": "76102",
                "addressCountry": "US"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 32.7511905,
                "longitude": -97.3409473
              },
              "openingHoursSpecification": [{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
                ],
                "opens": "08:30",
                "closes": "18:00"
              },{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Saturday",
                "opens": "09:00",
                "closes": "14:00"
              }],
              "sameAs": [
                "https://www.facebook.com/dmautoleasing/",
                "https://www.instagram.com/dmleasing/",
                "https://www.pinterest.com/dmleasing/"
              ]             
            }`}
          </script>
        )}
        {location.slug === 'houston-location' && (
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "AutoDealer",
              "name": "D&M Leasing Houston",
              "image": "https://www.dmautoleasing.com/dm-white.svg",
              "@id": "https://schema.org/AutoDealer",
              "url": "https://www.dmautoleasing.com/contact-us/houston-location/",
              "telephone": "(713) 290-1000",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "4635 Southwest Freeway, Ste. 1000",
                "addressLocality": "Houston",
                "addressRegion": "TX",
                "postalCode": "77027",
                "addressCountry": "US"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 29.7290983,
                "longitude": -95.4538116
              },
              "openingHoursSpecification": [{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
                ],
                "opens": "08:30",
                "closes": "18:00"
              },{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Saturday",
                "opens": "09:00",
                "closes": "14:00"
              }],
              "sameAs": [
                "https://www.facebook.com/dmautoleasing/",
                "https://www.instagram.com/dmleasing/",
                "https://www.pinterest.com/dmleasing/"
              ]             
            }`}
          </script>
        )}
      </Helmet>
      <LocationTemplate
        title={location.title}
        content={location.content}
        locationInformation={location.locationInformation}
        featuredImage={location.featuredImage}
        locationFeatures={location.locationFeatures}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query LocationById($id: String!) {
    wpLocation(id: { eq: $id }) {
      title
      content
      uri
      slug
      seo {
        fullHead
        schema {
          raw
        }
      }
      locationInformation {
        locationAddress
        locationPhone
        locationEmail
        locationHours
        locationDirectionsLink
      }
      locationFeatures {
        locationBullets {
          locationBulletText
          loocationBulletImage {
            altText
            mediaItemUrl
          }
        }
      }
      featuredImage {
        node {
          altText
          mediaItemUrl
        }
      }
    }
  }
`
